import React, { useEffect, useMemo, useState } from "react"
import { cn } from "@daybridge/cn"
import { Button, Icon, Label } from "@daybridge/components"
import { useTranslations } from "next-intl"
import { useFormik } from "formik"
import { v4 } from "uuid"
import { object, string } from "yup"
import { FormButton } from "../items/form/FormButton"
import { FormSection } from "../items/form/FormSection"
import { H3 } from "../../../../../../components/heading/H3"
import { styleForHue } from "../../../../../../lib/styleForHue"
import { Input } from "../../../../../../components/input/Input"
import { TimeZonePicker } from "../../../../../../menus/timezones/TimeZonePicker"
import { AreasPicker } from "../../../../../../menus/areas/AreaPicker"
import { TagsPicker } from "../../../../../../menus/tags/TagsPicker"
import { CalendarParticipantCombobox } from "../../../../../../menus/calendar-participants/CalendarParticipantPicker"
import { useAccount } from "../../../../../../data/account/useAccount"
import {
  CalendarParticipantInput,
  CreateCalendarCreateInput,
} from "../../../../../../data/_gen/types"
import { useCalendarOperations } from "../../../../../../data/calendars/useCalendars"
import { useGeoData } from "../../../../../../data/geodata/useGeoData"
import { useAreas } from "../../../../../../data/areas/useAreas"
import { useTags } from "../../../../../../data/tags/useTags"
import { CalendarParticipant } from "../../../../../../data/calendars/Calendar"
import { AreaLabel } from "../areas/AreaLabel"
import { AlertsSettings, alertsValidationSchema } from "./AlertsSettings"

type CalendarCreationProps = Omit<
  React.HTMLAttributes<HTMLDivElement>,
  "children"
> & {
  onClose: () => void
}

const CalendarCreationFn = React.forwardRef(
  (props: CalendarCreationProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { className, onClose, ...rest } = props

    const { data: user } = useAccount()
    const { data: geodata } = useGeoData()
    const { data: areas } = useAreas()
    const { data: tags } = useTags()
    const timezones = geodata?.timeZones

    const t = useTranslations("calendars")
    const tAlerts = useTranslations("alerts")

    const idempotencyKey = useMemo(() => v4(), [])
    const [participants, setParticipants] = useState<CalendarParticipant[]>([])

    const {
      create: { mutateAsync: create },
    } = useCalendarOperations()
    const formik = useFormik<CreateCalendarCreateInput>({
      initialValues: {
        name: "",
        participants: [],
        defaultEventAlerts: null,
        defaultAllDayEventAlerts: null,
      },
      validationSchema: object().shape({
        name: string().required().max(64),
        ...alertsValidationSchema,
      }),
      onSubmit: async (values) => {
        await create({ input: { create: values, idempotencyKey } }).then(
          onClose,
        )
      },
    })

    useEffect(() => {
      void formik.setFieldValue(
        "participants",
        participantsToInput(participants),
      )
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [participants])

    const calendar = formik.values
    const selectedZone = formik.values.timeZone
      ? timezones?.[formik.values.timeZone]
      : undefined
    const selectedArea = formik.values.defaultArea
      ? areas?.find((area) => area.id === formik.values.defaultArea)
      : undefined
    const selectedTags = formik.values.defaultTags
      ? tags?.filter((tag) => formik.values.defaultTags?.includes(tag.id))
      : []

    return (
      <>
        <Label
          className="m-4"
          theme="adaptive"
          style={
            selectedArea
              ? {
                  ...styleForHue(selectedArea.hue),
                }
              : undefined
          }
        >
          {t("create_calendar")}
        </Label>
        <div
          ref={ref}
          className={cn("flex flex-col", "w-[25rem]", className)}
          style={
            selectedArea
              ? {
                  ...styleForHue(selectedArea.hue),
                }
              : undefined
          }
          {...rest}
        >
          <div className="px-4 pb-4 flex flex-col">
            <Input
              {...formik.getFieldProps("name")}
              {...formik.getFieldMeta("name")}
              data-1p-ignore={true}
              data-lp-ignore={true}
              autoComplete="off"
              placeholder={t("name") + "..."}
              className={cn(
                "self-stretch w-auto max-w-none",
                "py-4",
                "text-lg",
              )}
              maxLength={64}
            />
          </div>
          <FormSection>
            <TimeZonePicker
              selectedZones={selectedZone ? [selectedZone] : undefined}
              selectionMode="radio"
              onZoneSelect={(zone) => {
                void formik.setFieldValue(
                  "timeZone",
                  zone ? zone.id : undefined,
                )
              }}
              showSystemDefaultOption
            >
              <FormButton
                icon="Globe"
                description={!selectedZone?.city && t("use_system_default")}
              >
                {selectedZone?.city || t("time_zone")}
              </FormButton>
            </TimeZonePicker>
          </FormSection>
          <FormSection>
            <AreasPicker
              selectionMode="radio"
              onAreaSelect={(value) => {
                void formik.setFieldValue("defaultArea", value.id)
              }}
              selectedAreas={calendar.defaultArea ? [calendar.defaultArea] : []}
              showAreaEditMenu
              showNoAreaOption
              noAreaOptionSelected={!calendar.defaultArea}
              onNoAreaChange={(value) => {
                if (value) {
                  void formik.setFieldValue("defaultArea", undefined)
                }
              }}
            >
              <FormButton icon={"Area"}>
                {selectedArea ? (
                  <AreaLabel area={selectedArea} />
                ) : (
                  t("assign_to_area")
                )}
              </FormButton>
            </AreasPicker>
          </FormSection>
          <FormSection>
            <TagsPicker
              selectionMode="checkbox"
              showTagEditMenu
              partitioned
              selectedTags={calendar.defaultTags || []}
              onTagSelect={(value) => {
                const selected = calendar.defaultTags?.includes(value.id)
                if (selected) {
                  void formik.setFieldValue(
                    "defaultTags",
                    calendar.defaultTags?.filter((tag) => tag !== value.id),
                  )
                } else {
                  void formik.setFieldValue("defaultTags", [
                    ...(calendar.defaultTags || []),
                    value.id,
                  ])
                }
              }}
            >
              <FormButton icon="Tag">
                {selectedTags && selectedTags.length > 0 ? (
                  <div className="w-full flex flex-row flex-wrap -mb-1 truncate">
                    {selectedTags?.slice(0, 5).map((tag) => (
                      <Label
                        theme="adaptive"
                        className="max-w-full text-sm mb-1 mr-1 truncate"
                        key={tag.id}
                        icon={tag.icon}
                        style={{
                          ...styleForHue(tag.hue),
                        }}
                      >
                        {tag.name}
                      </Label>
                    ))}
                    {selectedTags && selectedTags.length > 5 && (
                      <Icon
                        name="EllipsisHorizontal"
                        className="w-2 text-low-contrast"
                      />
                    )}
                  </div>
                ) : (
                  t("default_tags")
                )}
              </FormButton>
            </TagsPicker>
          </FormSection>

          <div className={cn("pt-6 pb-4 px-4", "space-y-1")}>
            <H3>{tAlerts("event_notifications")}</H3>
            <p className="text-base text-low-contrast leading-tight">
              {tAlerts("event_notifications_description")}
            </p>
          </div>
          {formik.values.defaultAllDayEventAlerts === null &&
          formik.values.defaultEventAlerts === null ? (
            <FormSection>
              <FormButton
                icon="Bell"
                onClick={() => {
                  void formik.setFieldValue(
                    "defaultEventAlerts",
                    user?.eventAlerts,
                  )
                  void formik.setFieldValue(
                    "defaultAllDayEventAlerts",
                    user?.allDayEventAlerts,
                  )
                }}
                description={tAlerts("click_to_customize")}
              >
                {tAlerts("using_default_settings")}
              </FormButton>
            </FormSection>
          ) : (
            <AlertsSettings formik={formik} />
          )}

          <div className="p-4 space-y-1">
            <H3>{t("invite_others")}</H3>
            <p className="text-base text-low-contrast leading-tight">
              {t("invite_others_description")}
            </p>
          </div>
          <div className="bg-elevated border-y border-tint">
            <CalendarParticipantCombobox
              maxHeight="250px"
              selectionMode="checkbox"
              participants={participants}
              onParticipantsUpdate={setParticipants}
              autofocus={false}
              prioritizeRole={true}
              permissions={{
                isMember: true,
                hasAccepted: true,
                canCreateItems: true,
                canModifyItems: true,
                canRenameForSelf: true,
                canRenameForEveryone: true,

                canModifyTimeZone: true,
                canModifyDefaultArea: true,
                canModifyDefaultTags: true,
                canModifyDefaultAlertSettings: true,

                canSeeParticipants: true,
                canInviteParticipant: true,
                canRemoveParticipant: (participant) =>
                  participant.type !== "user" ||
                  participant.user.id !== user?.id,
                canChangeParticipantRole: (participant) =>
                  participant.type !== "user" ||
                  participant.user.id !== user?.id,
                canLeave: () => false,
                canDelete: false,
              }}
            />
          </div>
          <div className={cn("flex flex-row justify-end", "space-x-2 p-3")}>
            <Button variant="translucent" onClick={onClose}>
              {t("cancel")}
            </Button>
            <Button
              variant="solid"
              theme="adaptive"
              disabled={!formik.isValid || formik.isSubmitting}
              onClick={() => void formik.submitForm()}
            >
              {t("create")}
            </Button>
          </div>
        </div>
      </>
    )
  },
)
CalendarCreationFn.displayName = "CalendarCreation"

const participantsToInput = (participants: CalendarParticipant[]) => {
  return participants.map(
    (participant): CalendarParticipantInput => ({
      role: participant.role,
      id:
        participant.type === "user"
          ? participant.user.id
          : participant.emailAddress,
    }),
  )
}

export const CalendarCreation = React.memo(
  CalendarCreationFn,
) as typeof CalendarCreationFn
