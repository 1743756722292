import { GraphQLError } from "./errors"

/**
 * `retryServerErrors` determines if a request should be retried in the event of an error.
 * It will only retry server (5xx) errors, not 4xx ones, up to a maximum of three times.
 *
 * @param failureCount The amount of times the request has failed so far.
 * @param err The error message from the last failure
 * @returns A boolean indicating whether or not the request should be retried.
 */
export const retryServerErrors = (
  failureCount: number,
  err: unknown,
): boolean => {
  // TODO - this is currently incorrect
  const message = err instanceof GraphQLError ? err.message : undefined
  if (message === "not_found" || message === "insufficient_permissions") {
    return false
  }

  // Don't retry 4xx errors
  const statusCode =
    err instanceof GraphQLError ? err.extensions?.status : undefined

  if (statusCode && statusCode >= 400 && statusCode < 500) {
    return false
  }

  // Retry all other errors twice (total 3 times)
  return failureCount < 3
}
