import { Dropdown, Label, MenuItem, MenuPage } from "@daybridge/components"
import { useTranslations } from "next-intl"
import { useCallback, useMemo } from "react"
import { IndexedItem, IndexedItemGroup } from "@daybridge/combo"
import { v4 } from "uuid"
import { useSearchParticipants } from "../../data/participants/useSearchParticipants"
import { extractEmail } from "../../lib/extractEmail"
import { CalendarParticipant } from "../../data/calendars/Calendar"
import {
  CalendarParticipantRole,
  CalendarParticipantStatus,
  IconId,
} from "../../data/_gen/types"
import { ItemIcon } from "../../app/[locale]/(boundary)/(calendar)/_components/items/types/_common/ItemIcon"
import { useAccount } from "../../data/account/useAccount"
import {
  CalendarPermissions,
  calendarRoleAtLeast,
} from "../../data/calendars/useCalendarPermissions"

export type CalendarParticipantsMenuOptions = {
  searchValue?: string
  setSearchValue?: (value: string) => void
  participants?: CalendarParticipant[]
  onParticipantsUpdate?: (participants: CalendarParticipant[]) => void
  permissions: CalendarPermissions
  prioritizeRole?: boolean
}

type CalendarParticipantsMenuInstanceGenerator = () => MenuPage

export const useCalendarParticipantsMenu = (
  options: CalendarParticipantsMenuOptions,
): CalendarParticipantsMenuInstanceGenerator => {
  const vars = useMemo(
    () => ({
      query: options?.searchValue ?? "",
    }),
    [options?.searchValue],
  )
  const { data: searchResults, isLoading: participantsLoading } =
    useSearchParticipants(vars)
  const t = useTranslations("calendars")
  const tNoResults = useTranslations("default_no_results_state")

  const { data: account } = useAccount()

  const addParticipant = useCallback(
    (participant: CalendarParticipant) => {
      options?.setSearchValue?.("")
      if (
        (participant.type === "user" &&
          options.participants?.some(
            (p) => p.type === "user" && p.user.id === participant.user.id,
          )) ||
        (participant.type === "email" &&
          options.participants?.some(
            (p) =>
              p.type === "email" && p.emailAddress === participant.emailAddress,
          ))
      ) {
        return
      }
      options?.onParticipantsUpdate?.([
        ...(options?.participants || []),
        participant,
      ])
    },
    [options],
  )

  const removeParticipant = useCallback(
    (id: string) => {
      options?.setSearchValue?.("")
      options?.onParticipantsUpdate?.(
        (options.participants || []).filter((p) => p.id !== id),
      )
    },
    [options],
  )

  const optionsMenuForParticipant = useCallback(
    (participant: CalendarParticipant): MenuPage => {
      const availableRoles: CalendarParticipantRole[] = Object.values(
        CalendarParticipantRole,
      )
        .filter(() => {
          return options.permissions.canChangeParticipantRole(participant)
        })
        .sort((a, b) => {
          return calendarRoleAtLeast(a, b) ? -1 : 1
        })

      return {
        items: [
          ...(availableRoles.length > 0
            ? [
                {
                  id: "change_role",
                  title: t("change_role_to"),
                  icon: "User",
                  maxSearchDepth: 0,
                  children: {
                    items: [
                      {
                        groupId: "roles",
                        selectionMode: "radio" as const,
                        items: availableRoles.map((role) => ({
                          id: role,
                          icon: iconForRole(role),
                          title: t(
                            role.toLowerCase() as Lowercase<CalendarParticipantRole>,
                          ),
                          selected: participant.role === role,
                          description: t(
                            (role.toLowerCase() +
                              "_description") as `${Lowercase<CalendarParticipantRole>}_description`,
                          ),
                          onSelect: () => {
                            options.onParticipantsUpdate?.(
                              (options.participants || []).map((p) => {
                                if (p.id === participant.id) {
                                  return {
                                    ...p,
                                    role,
                                  }
                                }
                                return p
                              }),
                            )
                          },
                        })),
                      },
                    ],
                  },
                },
              ]
            : []),
          ...(options.permissions.canRemoveParticipant(participant)
            ? [
                {
                  id: "remove",
                  title: t("remove"),
                  icon: "Cross",
                  hue: 0,
                  maxSearchDepth: 0,
                  onSelect: () => {
                    removeParticipant(participant.id)
                  },
                },
              ]
            : []),
        ],
      }
    },
    [t, options, removeParticipant],
  )

  const itemForParticipant = useCallback(
    (participant: CalendarParticipant, selected = false): MenuItem => {
      const menu = optionsMenuForParticipant(participant)

      const user = participant.type === "user" ? participant.user : undefined

      const emailAddress =
        participant.type === "email" ? participant.emailAddress : undefined

      return {
        id: participant.id,
        title: user ? user.name || "" : emailAddress || "",

        description: user
          ? `@${user.username || ""}`
          : selected
          ? t("added_by_email")
          : t("add_by_email"),

        selected,

        rightContent: selected ? (
          options.prioritizeRole ||
          participant.status === CalendarParticipantStatus.Accepted ? (
            <Label
              className="text-sm ml-4"
              theme="adaptive"
              icon={iconForRole(participant.role)}
            >
              {t(
                participant.role.toLowerCase() as Lowercase<CalendarParticipantRole>,
              )}
            </Label>
          ) : (
            <Label
              className="text-sm ml-4"
              theme="neutral"
              icon={IconId.Envelope}
            >
              {t("invited")}
            </Label>
          )
        ) : undefined,

        avatarUrl: user ? (user.avatarUrl ? user.avatarUrl : null) : undefined,

        disabled:
          (participant.type === "user" &&
            participant.user.id === account?.id) ||
          (selected && !options.permissions.canRemoveParticipant(participant)),

        expandChildrenAs: {
          mode: "menu",
          component: Dropdown,
        },
        children: selected && menu.items.length > 0 ? menu : undefined,

        icon: emailAddress ? (
          <ItemIcon className="w-8 p-1" name={IconId.Envelope} />
        ) : undefined,
        onSelect: () => {
          if (!selected) {
            addParticipant({
              id: participant.id,
              role: CalendarParticipantRole.Editor,
              status: CalendarParticipantStatus.Pending,
              ...(participant.type === "user"
                ? { type: "user", user: participant.user }
                : { type: "email", emailAddress: participant.emailAddress }),
            })
          } else {
            removeParticipant(participant.id)
          }
        },
      }
    },
    [
      addParticipant,
      removeParticipant,
      t,
      optionsMenuForParticipant,
      account,
      options,
    ],
  )

  const participantItems = useCallback((): MenuPage["items"] => {
    if (!options.participants || options.participants.length === 0) {
      return []
    }

    return [
      {
        groupId: "participants",
        selectionMode: "checkbox",
        items:
          options.participants?.map((participant) =>
            itemForParticipant(participant, true),
          ) ?? [],
      },
    ]
  }, [options.participants, itemForParticipant])

  const searchResultItems = useCallback((): MenuPage["items"] => {
    if (!options.permissions.canInviteParticipant) {
      return []
    }

    if (!searchResults || searchResults.length === 0) {
      return []
    }

    return [
      {
        groupId: "results",
        selectionMode: "checkbox",
        items:
          (searchResults
            ?.map((searchResult) => {
              // User IDs can be present via directly added users, or contacts
              const userId = searchResult.id

              // Filter out items from search results that are
              // already in the participants list
              if (
                options.participants?.some((p) => {
                  if (p.type === "user") {
                    return userId && p.user.id === userId
                  } else {
                    return false
                  }
                })
              ) {
                return null
              }

              return itemForParticipant(
                {
                  id: searchResult.id,
                  type: "user",
                  role: CalendarParticipantRole.Editor,
                  status: CalendarParticipantStatus.Pending,
                  user: searchResult,
                },
                false,
              )
            })
            .filter((x) => x !== null) as MenuItem[]) ?? [],
      },
    ]
  }, [
    searchResults,
    itemForParticipant,
    options.participants,
    options.permissions,
  ])

  const filterBehavior = useCallback(
    (
      input: string,
      items: IndexedItem[],
      path: IndexedItem[],
      defaultItems: IndexedItem[],
    ): (IndexedItem | IndexedItemGroup)[] => {
      if (!options.permissions.canInviteParticipant) {
        return defaultItems
      }

      if (extractEmail(input)) {
        if (
          options.participants?.some(
            (p) => p.type === "email" && p.emailAddress === input,
          )
        ) {
          return defaultItems
        }

        return [
          {
            groupId: "email",
            title: input,
            selectionMode: "checkbox" as const,
            items: [
              {
                globalId: path.join("/") + "/" + input,
                includeInSearchResults: false,
                path,
                ...itemForParticipant(
                  {
                    id: v4(),
                    type: "email",
                    role: CalendarParticipantRole.Editor,
                    emailAddress: input,
                    status: CalendarParticipantStatus.Pending,
                  },
                  false,
                ),
              } as IndexedItem,
            ],
          },
          ...defaultItems,
        ]
      }

      return defaultItems
    },
    [options.permissions, options.participants, itemForParticipant],
  )

  return useCallback(
    (): MenuPage => ({
      prompt: options.permissions.canInviteParticipant
        ? t("name_username_or_email_address") + "..."
        : t("search") + "...",
      noResultsTitle: tNoResults("no_results"),
      noResultsDescription: tNoResults("no_results_description"),
      noResultsIcon: "Invite",
      emptyStateIcon: "Invite",
      loading: participantsLoading,
      filterBehavior,
      items: [...participantItems(), ...searchResultItems()],
    }),
    [
      participantItems,
      searchResultItems,
      t,
      tNoResults,
      participantsLoading,
      filterBehavior,
      options,
    ],
  )
}

export const iconForRole = (role: CalendarParticipantRole) => {
  switch (role) {
    case CalendarParticipantRole.Owner:
      return "Star"
    case CalendarParticipantRole.Editor:
      return "Edit"
    case CalendarParticipantRole.Reader:
      return "Book"
    default:
      return "viewer"
  }
}
