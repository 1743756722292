"use client"

import { useTranslations } from "next-intl"
import Image from "next/image"
import React from "react"
import { Button } from "@daybridge/components"
import { H1 } from "../../../../components/heading/H1"
import { allowedMobilePathPrefixes } from "../../../../config/mobile"
import { Link, usePathname } from "../../../../lib/navigation"

type SwitchToDesktopProps = React.HTMLAttributes<HTMLDivElement> & {
  platform: "ios" | "android" | null
  show?: boolean
}

const SwitchToDesktopFn = React.forwardRef(
  (props: SwitchToDesktopProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { className, show, children, platform, ...rest } = props
    const t = useTranslations("app")
    const pathName = usePathname()

    if (
      !show ||
      pathName === null ||
      (pathName &&
        allowedMobilePathPrefixes.some((prefix) => pathName.startsWith(prefix)))
    ) {
      return <>{children}</>
    }

    return (
      <div
        ref={ref}
        className={`
          w-full h-full
          flex flex-col items-center justify-center
          ${className || ""}
        `}
        {...rest}
      >
        <div
          className="flex items-end px-3 pt-4 pb-0 mb-4 rounded-2xl shadow-sm overflow-hidden relative"
          style={{
            background:
              // TODO - Replace with Tailwind colours when brand is finalised
              "linear-gradient(119.18deg, rgba(255, 216, 170, 0.08) -11.85%, #FF3EA6 109.14%), #65BEFF",
          }}
        >
          <Image
            aria-hidden
            alt=""
            src="/images/illustrations/desktop.png"
            width={96}
            height={96}
            className="dark:hidden -mb-4"
          />
          <Image
            aria-hidden
            alt=""
            src="/images/illustrations/desktop-dark.png"
            width={96}
            height={96}
            className="hidden dark:block -mb-4"
          />
          <div className="absolute inset-0 border border-tint rounded-2xl" />
        </div>
        <H1>{t("download_the_app")}</H1>
        <div className="text-center px-6 text-base text-medium-contrast">
          {t("download_the_app_description")}
        </div>
        {platform && (
          <Link href={"/download/" + platform} className="mt-8 text-md !py-2">
            <Button icon={platform === "ios" ? "Apple" : "Android"}>
              {t(platform === "ios" ? "go_to_app_store" : "go_to_play_store")}
            </Button>
          </Link>
        )}
      </div>
    )
  },
)
SwitchToDesktopFn.displayName = "SwitchToDesktop"

export const SwitchToDesktop = React.memo(
  SwitchToDesktopFn,
) as typeof SwitchToDesktopFn
