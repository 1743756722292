import React, {
  useCallback,
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react"
import { useSetLocationBiasMutation } from "../../../../data/_gen/hooks"

export type LocationContext = {
  coordinates?: {
    latitude: number
    longitude: number
  }
  permission: "granted" | "denied" | "prompt"
  showPermissionsPrompt: boolean
  acceptPermissionsPrompt: () => void
  dismissPermissionsPrompt: () => void
}

const locationContext = createContext<LocationContext>({
  showPermissionsPrompt: false,
  permission: "prompt",
  acceptPermissionsPrompt: () => {
    throw new Error(
      "acceptPermissionsPrompt can only be called inside LocationProvider",
    )
  },
  dismissPermissionsPrompt: () => {
    throw new Error(
      "dismissPermissionsPrompt can only be called inside LocationProvider",
    )
  },
})

export const LocationProvider = React.memo(
  ({ children }: { children: React.ReactNode }) => {
    // Permissions prompt management
    const LOCATION_PROMPT_ANSWERED_STORAGE_KEY = "location-prompt-response"
    const LOCATION_PROMPT_ANSWERED_STORAGE_DISABLE_VALUE = "false"

    const [showPermissionPrompt, setShowPermissionPrompt] = useState(false)
    const alreadyDismissed =
      typeof window !== "undefined"
        ? window.localStorage.getItem(LOCATION_PROMPT_ANSWERED_STORAGE_KEY) ===
          LOCATION_PROMPT_ANSWERED_STORAGE_DISABLE_VALUE
        : false

    const onDismiss = useCallback(() => {
      if (typeof window === "undefined") return

      window.localStorage.setItem(
        LOCATION_PROMPT_ANSWERED_STORAGE_KEY,
        LOCATION_PROMPT_ANSWERED_STORAGE_DISABLE_VALUE,
      )
      setShowPermissionPrompt(false)
    }, [])

    const [permission, setPermission] = useState<
      "granted" | "denied" | "prompt"
    >("prompt")
    useEffect(() => {
      if (navigator.permissions) {
        navigator.permissions
          .query({ name: "geolocation" })
          .then((result) => {
            setPermission(result.state)
            result.onchange = () => {
              setPermission(result.state)
            }
          })
          .catch((error) => {
            console.error("Error checking geolocation permission:", error)
            setPermission("denied") // Fallback if permissions API fails
          })
      }
    }, [])

    useEffect(() => {
      switch (permission) {
        case "granted":
          // Permission already granted - start now
          setShowPermissionPrompt(false)
          break
        case "prompt":
          if (alreadyDismissed) {
            // The user has already said no to the popup dialog
            setShowPermissionPrompt(false)
            return
          } else {
            setShowPermissionPrompt(true)
          }
          break
        default:
          // Dont' need to ask for permission
          setShowPermissionPrompt(false)
          break
      }
    }, [permission, alreadyDismissed])

    const [location, setLocation] = useState<
      LocationContext["coordinates"] | undefined
    >()
    const watcherRef = useRef<number | null>(null)
    const startLocationTracking = useCallback(() => {
      if ("geolocation" in navigator && permission === "granted") {
        if (watcherRef.current !== null) {
          navigator.geolocation.clearWatch(watcherRef.current)
        }
        watcherRef.current = navigator.geolocation.watchPosition(
          (position) => {
            setLocation({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            })
          },
          console.error,
          { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 },
        )
      }
    }, [permission])

    useEffect(() => {
      startLocationTracking()

      return () => {
        // Clean up by removing the watcher when the component unmounts
        if (watcherRef.current !== null) {
          navigator.geolocation.clearWatch(watcherRef.current)
        }
      }
    }, [startLocationTracking])

    const acceptPermissionsPrompt = useCallback(() => {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          () => {
            startLocationTracking()

            // Needed for Safari
            setPermission("granted")
          },
          () => {
            // Needed for Safari
            setPermission("denied")
          },
          { timeout: 10000 },
        )
      }
    }, [startLocationTracking])

    const { mutate: submitLocation } = useSetLocationBiasMutation()
    useEffect(() => {
      if (location) {
        submitLocation({
          latitude: location.latitude,
          longitude: location.longitude,
        })
      }
    }, [location, submitLocation])

    return (
      <locationContext.Provider
        value={{
          coordinates: location,
          showPermissionsPrompt: showPermissionPrompt,
          acceptPermissionsPrompt,
          dismissPermissionsPrompt: onDismiss,
          permission,
        }}
      >
        {children}
      </locationContext.Provider>
    )
  },
)
LocationProvider.displayName = "LocationProvider"

export const useLocation = () => useContext(locationContext)
