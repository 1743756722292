"use client"

/**
 * This file defines a client component that bundles global application
 * providers into one place.
 */

import { Session } from "next-auth"
import { SessionProvider } from "next-auth/react"
import { ThemeProvider } from "next-themes"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import {
  DisplayedDateRangeProvider,
  NavigationDateProvider,
  TooltipProvider,
} from "@daybridge/components"
import { UserAgent } from "next-useragent"
import { OptimismProvider } from "@daybridge/optimism"
import GraphQLClientProvider from "../../../lib/graphql/provider"
import { useNow } from "../../../lib/useNow"
import { AppUpdateProvider } from "./_providers/AppUpdateProvider"
import { FlowProvider } from "./_providers/FlowProvider"
import { QueryClientProvider } from "./_providers/QueryClientProvider"
import { DateTimeInteractionProvider } from "./(calendar)/_providers/DateTimeInteractionProvider"
import { UserAgentProvider } from "./_providers/UserAgentProvider"
import { useBaseURL } from "./_providers/BaseURLProvider"
import { NotificationsProvider } from "./_providers/NotificationsProvider"
import { LocationProvider } from "./_providers/LocationProvider"

interface ProvidersProps {
  children: React.ReactElement
  session: Session | null
  userAgent: UserAgent
  nonce: string
}

export const Providers: React.FC<ProvidersProps> = ({
  children,
  session,
  userAgent,
  nonce,
}) => {
  const { baseURL } = useBaseURL()
  const now = useNow()

  if (!baseURL) {
    throw new Error("NEXT_PUBLIC_API_BASE_URL is not defined")
  }

  return (
    <UserAgentProvider value={userAgent}>
      <AppUpdateProvider>
        <SessionProvider
          session={session}
          refetchWhenOffline={false}
          // Set to false to avoid race conditions. Our networking code fetches
          // data on window focus and will refresh the session if it's expired.
          refetchOnWindowFocus={false}
        >
          <QueryClientProvider>
            <GraphQLClientProvider serverUrl={baseURL}>
              <ThemeProvider
                attribute="class"
                enableSystem={true}
                // The nonce should not be added on the client side, only on the
                // server side. See https://github.com/kentcdodds/nonce-hydration-issues
                nonce={typeof window === "undefined" ? nonce : ""}
              >
                <TooltipProvider>
                  <OptimismProvider>
                    <NotificationsProvider>
                      <LocationProvider>
                        <FlowProvider>
                          <DisplayedDateRangeProvider>
                            <NavigationDateProvider now={now}>
                              <DateTimeInteractionProvider>
                                {children}
                              </DateTimeInteractionProvider>
                            </NavigationDateProvider>
                          </DisplayedDateRangeProvider>
                        </FlowProvider>
                      </LocationProvider>
                    </NotificationsProvider>
                    <ReactQueryDevtools
                      initialIsOpen={false}
                      position="bottom-right"
                    />
                  </OptimismProvider>
                </TooltipProvider>
              </ThemeProvider>
            </GraphQLClientProvider>
          </QueryClientProvider>
        </SessionProvider>
      </AppUpdateProvider>
    </UserAgentProvider>
  )
}
