import { cn } from "@daybridge/cn"
import { Combobox, ComboboxProps } from "@daybridge/components"
import React from "react"
import { useBeginFlow } from "../../app/[locale]/(boundary)/_providers/FlowProvider"
import { FeedbackType } from "../../data/_gen/types"
import { useFeedbackMenu } from "./feedback-menu"

type FeedbackProps = Omit<ComboboxProps, "root">

const FeedbackFn = React.forwardRef(
  (props: FeedbackProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { className, ...rest } = props
    const beginFlow = useBeginFlow()

    const root = useFeedbackMenu()({
      onItemSelect: (item: FeedbackType) => {
        beginFlow({ id: "feedback_form", params: { feedbackType: item } })
      },
    })

    return (
      <Combobox
        className={cn("w-[500px] max-w-full")}
        maxHeight="500px"
        ref={ref}
        inlineInput
        root={root}
        {...rest}
      />
    )
  },
)
FeedbackFn.displayName = "Feedback"

export const Feedback = React.memo(FeedbackFn) as typeof FeedbackFn
