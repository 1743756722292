import React, { useEffect, useMemo, useState } from "react"
import {
  Combobox,
  MenuItemGroup,
  MenuPage,
  Popover,
  PopoverProps,
} from "@daybridge/components"
import { cn } from "@daybridge/cn"
import { useQueryClient } from "@tanstack/react-query"
import { CalendarParticipant } from "../../data/calendars/Calendar"
import { useSearchParticipantsQuery } from "../../data/_gen/hooks"
import { CalendarPermissions } from "../../data/calendars/useCalendarPermissions"
import { useCalendarParticipantsMenu } from "./calendar-participants-menu"

const CalendarParticipantPickerFn = React.forwardRef(
  (
    props: Omit<PopoverProps, "content"> &
      Omit<CalendarParticipantComboboxProps, "onComplete">,
    ref: React.ForwardedRef<HTMLButtonElement>,
  ) => {
    const {
      children,
      className,
      maxHeight,
      selectionMode,
      participants,
      onParticipantsUpdate,
      permissions,
      ...rest
    } = props
    const [open, setOpen] = React.useState(false)

    return (
      <Popover
        open={open}
        onOpenChange={setOpen}
        content={
          <CalendarParticipantCombobox
            onComplete={() => setOpen(false)}
            maxHeight={maxHeight}
            selectionMode={selectionMode}
            participants={participants}
            onParticipantsUpdate={onParticipantsUpdate}
            permissions={permissions}
          />
        }
        className={cn("w-80", className)}
        ref={ref}
        {...rest}
      >
        {children}
      </Popover>
    )
  },
)
CalendarParticipantPickerFn.displayName = "CalendarParticipantPicker"
export const CalendarParticipantPicker = React.memo(
  CalendarParticipantPickerFn,
) as typeof CalendarParticipantPickerFn

export type CalendarParticipantComboboxProps = {
  onComplete?: () => void
  hue?: number
  maxHeight?: string
  selectionMode?: MenuItemGroup["selectionMode"]
  participants?: CalendarParticipant[]
  onParticipantsUpdate?: (participants: CalendarParticipant[]) => void
  permissions: CalendarPermissions
  prioritizeRole?: boolean
  autofocus?: boolean
}
const CalendarParticipantComboboxFn = React.forwardRef(
  (
    props: CalendarParticipantComboboxProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const {
      onComplete,
      maxHeight,
      participants,
      permissions,
      onParticipantsUpdate,
      prioritizeRole,
      autofocus,
    } = props

    const queryClient = useQueryClient()
    useEffect(() => {
      return () => {
        queryClient.removeQueries(useSearchParticipantsQuery.getKey())
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const inputState = useState<string>("")

    const menu = useCalendarParticipantsMenu({
      searchValue: inputState[0],
      setSearchValue: inputState[1],
      permissions,
      participants,
      onParticipantsUpdate,
      prioritizeRole,
    })
    const root = useMemo<MenuPage>(() => menu(), [menu])

    return (
      <Combobox
        ref={ref}
        root={root}
        autofocus={autofocus}
        onComplete={onComplete}
        maxHeight={maxHeight}
        inputState={inputState}
      />
    )
  },
)
CalendarParticipantComboboxFn.displayName = "CalendarParticipantCombobox"
export const CalendarParticipantCombobox = React.memo(
  CalendarParticipantComboboxFn,
) as typeof CalendarParticipantComboboxFn
