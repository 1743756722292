import { MenuPage } from "@daybridge/components"
import { useTranslations } from "next-intl"
import { useCallback } from "react"
import { FeedbackType } from "../../data/_gen/types"

export type FeedbackMenuInstanceOptions = {
  onItemSelect?: (itemId: FeedbackType) => void
}

type FeedbackMenuInstanceGenerator = (
  opts?: FeedbackMenuInstanceOptions,
) => MenuPage

const feedbackTypes: FeedbackType[] = [
  FeedbackType.ReportUser,
  FeedbackType.Bug,
  FeedbackType.IdeaSuggestion,
  FeedbackType.Translation,
  FeedbackType.Security,
  FeedbackType.AccountBilling,
  FeedbackType.Other,
] as const

export const iconForFeedbackType = (type: (typeof feedbackTypes)[number]) => {
  switch (type) {
    case FeedbackType.ReportUser:
      return "Warning"
    case FeedbackType.Bug:
      return "Bug"
    case FeedbackType.IdeaSuggestion:
      return "ThoughtBubble"
    case FeedbackType.Translation:
      return "Language"
    case FeedbackType.Security:
      return "PadlockLocked"
    case FeedbackType.AccountBilling:
      return "Heart"
    case FeedbackType.Other:
      return "General"
  }
}

export const useFeedbackMenu = (): FeedbackMenuInstanceGenerator => {
  const t = useTranslations("feedback")
  const tNoResults = useTranslations("default_no_results_state")

  const items = useCallback(
    (opts?: FeedbackMenuInstanceOptions): MenuPage["items"] =>
      feedbackTypes.map((type) => ({
        id: type,
        title: t(type),
        description: t(`${type}_description`),
        icon: iconForFeedbackType(type),
        onSelect: opts?.onItemSelect
          ? () => opts.onItemSelect?.(type)
          : undefined,
      })),
    [t],
  )

  return useCallback(
    (opts?: FeedbackMenuInstanceOptions): MenuPage => ({
      prompt: t("how_can_we_help"),
      noResultsTitle: tNoResults("no_results"),
      noResultsDescription: tNoResults("no_results_description"),
      items: items(opts),
    }),
    [items, tNoResults, t],
  )
}
