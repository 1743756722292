import React, { useEffect, useMemo, useState } from "react"
import { cn } from "@daybridge/cn"
import { Button, Icon, Label } from "@daybridge/components"
import { useTranslations } from "next-intl"
import { useFormik } from "formik"
import { object, string } from "yup"
import {
  Calendar,
  CalendarParticipant,
} from "../../../../../../data/calendars/Calendar"
import { H3 } from "../../../../../../components/heading/H3"
import { styleForHue } from "../../../../../../lib/styleForHue"
import { CalendarParticipantCombobox } from "../../../../../../menus/calendar-participants/CalendarParticipantPicker"
import { useAccount } from "../../../../../../data/account/useAccount"
import { useCalendarOperations } from "../../../../../../data/calendars/useCalendars"
import {
  CalendarParticipantPatchInput,
  OAuthProviderId,
  UpdateCalendarPatchInput,
} from "../../../../../../data/_gen/types"
import { Input } from "../../../../../../components/input/Input"
import { useAreas } from "../../../../../../data/areas/useAreas"
import { useGeoData } from "../../../../../../data/geodata/useGeoData"
import { useTags } from "../../../../../../data/tags/useTags"
import { AreasPicker } from "../../../../../../menus/areas/AreaPicker"
import { TagsPicker } from "../../../../../../menus/tags/TagsPicker"
import { TimeZonePicker } from "../../../../../../menus/timezones/TimeZonePicker"
import { AreaLabel } from "../areas/AreaLabel"
import { FormButton } from "../items/form/FormButton"
import { FormSection } from "../items/form/FormSection"
import { useCalendarPermissions } from "../../../../../../data/calendars/useCalendarPermissions"
import { AlertsSettings, alertsValidationSchema } from "./AlertsSettings"

type CalendarEditProps = Omit<
  React.HTMLAttributes<HTMLDivElement>,
  "children"
> & {
  calendar?: Calendar
  onClose: () => void
}

const CalendarEditFn = React.forwardRef(
  (props: CalendarEditProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { className, onClose, calendar: flowCal, ...rest } = props

    const { data: user } = useAccount()
    const { data: geodata } = useGeoData()
    const { data: areas } = useAreas()
    const { data: tags } = useTags()
    const timezones = geodata?.timeZones
    const t = useTranslations("calendars")
    const tAlerts = useTranslations("alerts")

    const {
      update: { mutateAsync: update },
    } = useCalendarOperations()

    const [participants, setParticipants] = useState<CalendarParticipant[]>(
      flowCal?.participants || [],
    )
    useEffect(() => {
      setParticipants(flowCal?.participants || [])
    }, [flowCal?.participants])

    const formik = useFormik<UpdateCalendarPatchInput>({
      initialValues: {
        name: flowCal?.customName || flowCal?.name,
        timeZone: flowCal?.timeZone?.id,
        defaultArea: flowCal?.defaultArea?.id,
        defaultTags: flowCal?.defaultTags?.map((tag) => tag.id),
        defaultEventAlerts: flowCal?.defaultEventAlerts,
        defaultAllDayEventAlerts: flowCal?.defaultAllDayEventAlerts,
      },
      validationSchema: object().shape({
        name: string().required().max(64),
        ...alertsValidationSchema,
      }),
      onSubmit: async (values) => {
        if (!flowCal) return

        await update({
          input: {
            id: flowCal.id,
            patch: {
              ...(permissions?.canRenameForEveryone
                ? { name: values.name, customName: null }
                : permissions?.canRenameForSelf
                ? { customName: values.name }
                : {}),
              ...(permissions?.canModifyDefaultArea
                ? { defaultArea: values.defaultArea }
                : {}),
              ...(permissions?.canModifyDefaultTags
                ? { defaultTags: values.defaultTags }
                : {}),
              ...(permissions?.canModifyTimeZone
                ? { timeZone: values.timeZone }
                : {}),
              ...(permissions?.canModifyDefaultAlertSettings
                ? {
                    defaultEventAlerts: values.defaultEventAlerts,
                    defaultAllDayEventAlerts: values.defaultAllDayEventAlerts,
                  }
                : {}),
              ...(permissions?.canInviteParticipant
                ? {
                    participants: generateParticipantPatch(
                      flowCal.participants || [],
                      participants || [],
                    ),
                  }
                : {}),
            },
          },
        }).then(onClose)
      },
    })

    const values = formik.values
    const selectedZone = formik.values.timeZone
      ? timezones?.[formik.values.timeZone]
      : undefined
    const selectedArea = formik.values.defaultArea
      ? areas?.find((area) => area.id === formik.values.defaultArea)
      : undefined
    const selectedTags = formik.values.defaultTags
      ? tags?.filter((tag) => formik.values.defaultTags?.includes(tag.id))
      : []

    const permissionsForCalendar = useCalendarPermissions()
    const permissions = useMemo(
      () => flowCal && permissionsForCalendar(flowCal),
      [flowCal, permissionsForCalendar],
    )

    if (!permissions) return null

    return (
      <>
        <Label
          className="m-4"
          theme="adaptive"
          style={
            selectedArea
              ? {
                  ...styleForHue(selectedArea?.hue),
                }
              : {}
          }
        >
          {flowCal?.name}
        </Label>
        <div
          ref={ref}
          className={cn("flex flex-col", "w-[25rem]", className)}
          style={
            selectedArea
              ? {
                  ...styleForHue(selectedArea.hue),
                }
              : {}
          }
          {...rest}
        >
          {(permissions.canRenameForEveryone ||
            permissions.canRenameForSelf) && (
            <div className="px-4 pb-4 flex flex-col">
              <Input
                {...formik.getFieldProps("name")}
                {...formik.getFieldMeta("name")}
                data-1p-ignore={true}
                data-lp-ignore={true}
                autoComplete="off"
                placeholder={t("name") + "..."}
                className={cn(
                  "self-stretch w-auto max-w-none",
                  "py-4",
                  "text-lg",
                )}
                maxLength={64}
              />
            </div>
          )}
          {permissions.canModifyTimeZone && (
            <FormSection>
              <TimeZonePicker
                selectedZones={selectedZone ? [selectedZone] : undefined}
                selectionMode="radio"
                onZoneSelect={(zone) => {
                  void formik.setFieldValue(
                    "timeZone",
                    zone ? zone.id : undefined,
                  )
                }}
                showSystemDefaultOption
              >
                <FormButton
                  icon="Globe"
                  description={!selectedZone?.city && t("use_system_default")}
                >
                  {selectedZone?.city || t("time_zone")}
                </FormButton>
              </TimeZonePicker>
            </FormSection>
          )}
          {permissions.canModifyDefaultArea && (
            <FormSection>
              <AreasPicker
                selectionMode="radio"
                onAreaSelect={(value) => {
                  void formik.setFieldValue("defaultArea", value.id)
                }}
                selectedAreas={values.defaultArea ? [values.defaultArea] : []}
                showAreaEditMenu
                showNoAreaOption
                noAreaOptionSelected={!values.defaultArea}
                onNoAreaChange={(value) => {
                  if (value) {
                    void formik.setFieldValue("defaultArea", undefined)
                  }
                }}
              >
                <FormButton icon={"Area"}>
                  {selectedArea ? (
                    <AreaLabel area={selectedArea} />
                  ) : (
                    t("assign_to_area")
                  )}
                </FormButton>
              </AreasPicker>
            </FormSection>
          )}
          {permissions.canModifyDefaultTags && (
            <FormSection>
              <TagsPicker
                selectionMode="checkbox"
                showTagEditMenu
                partitioned
                selectedTags={values.defaultTags || []}
                onTagSelect={(value) => {
                  const selected = values.defaultTags?.includes(value.id)
                  if (selected) {
                    void formik.setFieldValue(
                      "defaultTags",
                      values.defaultTags?.filter((tag) => tag !== value.id),
                    )
                  } else {
                    void formik.setFieldValue("defaultTags", [
                      ...(values.defaultTags || []),
                      value.id,
                    ])
                  }
                }}
              >
                <FormButton icon="Tag">
                  {selectedTags && selectedTags.length > 0 ? (
                    <div className="w-full flex flex-row flex-wrap -mb-1 truncate">
                      {selectedTags?.slice(0, 5).map((tag) => (
                        <Label
                          theme="adaptive"
                          className="max-w-full text-sm mb-1 mr-1 truncate"
                          key={tag.id}
                          icon={tag.icon}
                          style={{
                            ...styleForHue(tag.hue),
                          }}
                        >
                          {tag.name}
                        </Label>
                      ))}
                      {selectedTags && selectedTags.length > 5 && (
                        <Icon
                          name="EllipsisHorizontal"
                          className="w-2 text-low-contrast"
                        />
                      )}
                    </div>
                  ) : (
                    t("default_tags")
                  )}
                </FormButton>
              </TagsPicker>
            </FormSection>
          )}
          {permissions.canModifyDefaultAlertSettings && (
            <>
              <div className={cn("pt-6 pb-4 px-4", "space-y-1")}>
                <H3>{tAlerts("event_notifications")}</H3>
                <p className="text-base text-low-contrast leading-tight">
                  {tAlerts("event_notifications_description")}
                </p>
              </div>
              {values?.defaultAllDayEventAlerts === null &&
              values.defaultEventAlerts === null ? (
                <FormSection>
                  <FormButton
                    icon="Bell"
                    onClick={() => {
                      void formik.setFieldValue(
                        "defaultEventAlerts",
                        user?.eventAlerts,
                      )
                      void formik.setFieldValue(
                        "defaultAllDayEventAlerts",
                        user?.allDayEventAlerts,
                      )
                    }}
                    description={tAlerts("click_to_customize")}
                  >
                    {tAlerts("using_default_settings")}
                  </FormButton>
                </FormSection>
              ) : (
                <AlertsSettings formik={formik} />
              )}
            </>
          )}

          {permissions.canInviteParticipant ? (
            <div className={cn("pt-6 pb-4 px-4", "space-y-1")}>
              <H3>{t("manage_sharing")}</H3>
              <p className="text-base text-low-contrast leading-tight">
                {t("invite_others_description")}
              </p>
            </div>
          ) : (
            <div
              className={cn(
                permissions.canModifyDefaultTags ? "p-4" : "px-4 pb-4",
                "space-y-1",
              )}
            >
              <p className="text-base text-low-contrast leading-tight">
                {flowCal?.account?.providerId === OAuthProviderId.Daybridge
                  ? t("insufficient_permissions_description")
                  : t("share_using_daybridge")}
              </p>
            </div>
          )}
          <div className="bg-elevated border-y border-tint">
            <CalendarParticipantCombobox
              maxHeight="70vh"
              selectionMode="checkbox"
              participants={participants}
              onParticipantsUpdate={(participants) => {
                setParticipants(participants)
              }}
              permissions={permissions}
            />
          </div>

          <div className={cn("flex flex-row justify-end", "space-x-2 p-3")}>
            <Button variant="translucent" onClick={onClose}>
              {permissions.hasAccepted ? t("cancel") : t("close")}
            </Button>
            {permissions.hasAccepted && (
              <Button
                variant="solid"
                theme="adaptive"
                disabled={!formik.isValid || formik.isSubmitting}
                onClick={() => void formik.submitForm()}
              >
                {t("save")}
              </Button>
            )}
          </div>
        </div>
      </>
    )
  },
)
CalendarEditFn.displayName = "CalendarEdit"

export const CalendarEdit = React.memo(CalendarEditFn) as typeof CalendarEditFn

const generateParticipantPatch = (
  originalParticipants: CalendarParticipant[],
  newParticipants: CalendarParticipant[],
): CalendarParticipantPatchInput[] => {
  const originalParticipantsById = new Map(
    originalParticipants.map((p) => [p.id, p]),
  )
  const newParticipantsById = new Map(newParticipants.map((p) => [p.id, p]))

  const participantsToRemove = originalParticipants.filter(
    (p) => !newParticipantsById.has(p.id),
  )

  const participantsToAdd = newParticipants.filter(
    (p) => !originalParticipantsById.has(p.id),
  )

  const participantsWithChangedRoleOrStatus = newParticipants.filter(
    (p) =>
      originalParticipantsById.has(p.id) &&
      (originalParticipantsById.get(p.id)?.role !== p.role ||
        originalParticipantsById.get(p.id)?.status !== p.status),
  )

  return [
    ...participantsToRemove.map(
      (p): CalendarParticipantPatchInput => ({
        id: p.id,
        delete: true,
      }),
    ),
    ...participantsToAdd.map(
      (p): CalendarParticipantPatchInput => ({
        id: p.type === "user" ? p.user.id : p.emailAddress,
        role: p.role,
      }),
    ),
    ...participantsWithChangedRoleOrStatus.map(
      (p): CalendarParticipantPatchInput => {
        const originalParticipant = originalParticipantsById.get(p.id)
        if (!originalParticipant) {
          throw new Error("Participant not found")
        }

        return {
          id: p.id,
          ...(originalParticipant.role === p.role ? {} : { role: p.role }),
          ...(originalParticipant.status === p.status
            ? {}
            : { status: p.status }),
        }
      },
    ),
  ]
}
