import { Place as GraphQLPlace } from "../_gen/types"
import { Place } from "./types"

export const placeFromGraphQL = (place: GraphQLPlace): Place => {
  return {
    _type: "Place",
    id: place.id,
    name: place.displayName,
    description: place.secondaryText,
    latitude: place.coordinates?.latitude,
    longitude: place.coordinates?.longitude,
    url: place.googleMapsUri || undefined,
    bottomLeft: place.viewport?.low
      ? [place.viewport.low.latitude, place.viewport.low.longitude]
      : undefined,
    topRight: place.viewport?.high
      ? [place.viewport.high.latitude, place.viewport.high.longitude]
      : undefined,
  }
}
