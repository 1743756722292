import React, { useCallback } from "react"
import { cn } from "@daybridge/cn"
import {
  Button,
  Combobox,
  DropZone,
  Dropdown,
  Editor,
  EditorValue,
  Icon,
  Label,
  serializeEditorValue,
  useToast,
} from "@daybridge/components"
import { useTranslations } from "next-intl"
import { Formik } from "formik"
import { v4 } from "uuid"
import {
  FeedbackType,
  FileUpload,
  UploadType,
} from "../../../../data/_gen/types"
import { iconForFeedbackType } from "../../../../menus/feedback/feedback-menu"
import { useSendFeedbackMutation } from "../../../../data/_gen/hooks"
import { useUploadFile } from "../../../../data/file-uploads/useUploadFile"
import { FormButton } from "../(calendar)/_components/items/form/FormButton"
import { humanFileSize } from "../../../../lib/humanFileSize"

const supportedFileTypes = [
  "image/gif",
  "image/jpeg",
  "image/png",
  "video/mp4",
  "video/quicktime",
  "video/mpeg",
]

type FeedbackFormProps = Omit<
  React.HTMLAttributes<HTMLDivElement>,
  "children"
> & {
  feedbackType?: FeedbackType
  onClose: () => void
}

type FileUploadWithLocalName = FileUpload & { localName: string }

interface FormValues {
  message?: EditorValue
}

const FeedbackFormFn = React.forwardRef(
  (props: FeedbackFormProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { className, feedbackType, onClose, ...rest } = props
    const t = useTranslations("feedback")
    const tEditor = useTranslations("editor")
    const tFileUploads = useTranslations("file_uploads")

    const toast = useToast()

    // Attachments
    const [fileUploads, setFileUploads] = React.useState<
      FileUploadWithLocalName[]
    >([])

    const upload = useUploadFile()
    const inputRef = React.useRef<HTMLInputElement>(null)
    const openFilePicker = useCallback(() => {
      inputRef.current?.click()
    }, [])

    const { mutateAsync } = useSendFeedbackMutation()
    const onSubmit = useCallback(
      async (values: FormValues) => {
        if (!feedbackType) return

        const promise = mutateAsync({
          input: {
            idempotencyKey: v4(),
            feedbackType: feedbackType,
            message: serializeEditorValue(values.message) ?? "",
            uploadIds: fileUploads.map((file) => file.id),
          },
        })

        toast.fromPromise(
          promise,
          {
            title: t("sending_message"),
          },
          {
            title: t("message_sent"),
          },
          {
            title: t("problem_sending_message"),
          },
        )

        await promise.then(onClose)
      },
      [feedbackType, mutateAsync, onClose, toast, t, fileUploads],
    )

    const uploadFiles = useCallback(
      (files: File[]) => {
        if (!files.length) return

        if (fileUploads.length + files.length > 25) {
          toast.error({
            title: tFileUploads("file_limit_reached"),
            message: tFileUploads("maximum_files", {
              count: 25,
            }),
            icon: "Warning",
          })
          return
        }

        const filesInLimit: File[] = []
        const filesNotSupported: File[] = []
        const filesExceedLimit: File[] = []
        files.forEach((file) => {
          if (!supportedFileTypes.includes(file.type)) {
            filesNotSupported.push(file)
          } else if (file.size <= 50 * 1024 * 1024) {
            filesInLimit.push(file)
          } else {
            filesExceedLimit.push(file)
          }
        })

        filesNotSupported.forEach((file) => {
          toast.error({
            title: tFileUploads("file_type_not_supported", {
              name: file.name,
            }),
            icon: "Warning",
          })
        })

        filesExceedLimit.forEach((file) => {
          toast.error({
            title: tFileUploads("file_too_large", {
              name: file.name,
            }),
            message: tFileUploads("maximum_size", {
              size: "50MB",
            }),
            icon: "Warning",
          })
        })

        if (filesInLimit.length === 0) return
        const promises = filesInLimit.map((file) =>
          upload(v4(), file, UploadType.Feedback, file.type).then(
            (fileUpload) => {
              const fileUploadWithLocalName: FileUploadWithLocalName = {
                ...fileUpload,
                localName: file.name,
              }
              return fileUploadWithLocalName
            },
          ),
        )
        const allPromises = Promise.all(promises)

        void allPromises.then((uploadedFiles) => {
          const newFileUploads = [...uploadedFiles, ...fileUploads]
          return setFileUploads(newFileUploads)
        })

        if (inputRef.current) inputRef.current.value = ""
      },
      [fileUploads, setFileUploads, toast, tFileUploads, upload],
    )

    if (!feedbackType) {
      return null
    }

    return (
      <Formik<FormValues>
        initialValues={{
          message: undefined,
        }}
        onSubmit={onSubmit}
      >
        {({ values: { message }, setFieldValue, isSubmitting, submitForm }) => (
          <>
            <DropZone
              onDrop={(files) => {
                uploadFiles(files)
              }}
            />
            <Label
              className="m-4"
              theme="brand-blue"
              icon={iconForFeedbackType(feedbackType)}
            >
              {t(feedbackType)}
            </Label>
            <div
              ref={ref}
              className={cn("flex flex-col", "w-[25rem]", "px-5", className)}
              {...rest}
            >
              <Editor
                value={message}
                onChange={(value) => {
                  void setFieldValue("message", value)
                }}
                className={cn("text-md", className)}
                placeholder={t("your_message") + "..."}
                boldTooltip={tEditor("bold")}
                italicTooltip={tEditor("italic")}
                underlineTooltip={tEditor("underline")}
                strikethroughTooltip={tEditor("strikethrough")}
                maxLength={8192}
              />
            </div>

            {fileUploads.length < 25 && (
              <FormButton
                icon="Paperclip"
                onClick={openFilePicker}
                description={t("or_drag_and_drop_files")}
                className={cn("border-y border-tint-light", "mt-6")}
              >
                {t("attach_screenshots_and_videos")}
              </FormButton>
            )}

            <input
              tabIndex={-1} // Make sure we can't invoke the input manually.
              className="opacity-0 fixed pointer-events-none"
              ref={inputRef}
              type="file"
              multiple
              // Accept images and videos
              accept={supportedFileTypes.join(", ")}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.files && e.target.files.length > 0) {
                  const files = inputRef.current?.files
                    ? Array.from(inputRef.current?.files)
                    : []
                  uploadFiles(files)
                }
              }}
            />

            {fileUploads.length > 0 && (
              <Combobox
                hideSearchBox
                root={{
                  items: fileUploads.map((upload) => ({
                    id: upload.id,
                    title: upload.localName,
                    description: humanFileSize(upload.size),
                    icon: upload.mediaType.toLowerCase().startsWith("image") ? (
                      <div
                        className={cn(
                          "w-8 h-8 rounded-md shadow",
                          "ring-1 ring-inset ring-tint-extra-heavy",
                        )}
                        style={{
                          backgroundImage: `url(${upload.fileUrl})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      />
                    ) : (
                      <div
                        className={cn(
                          "w-8 h-8 rounded-md",
                          "ring-1 ring-inset ring-tint-extra-heavy",
                          "bg-elevated shadow text-brand-blue-primary",
                          "flex items-center justify-center",
                        )}
                      >
                        <Icon name="Movies" className="w-3 h-3" />
                      </div>
                    ),
                    children: {
                      items: [
                        {
                          id: "delete",
                          icon: "TrashEmpty",
                          title: t("delete_attachment"),
                          onSelect: () => {
                            const newFileUploads = fileUploads.filter(
                              (file) => file.id !== upload.id,
                            )
                            setFileUploads(newFileUploads)
                          },
                        },
                      ],
                    },
                    expandChildrenAs: {
                      mode: "menu",
                      component: Dropdown,
                    },
                  })),
                }}
              />
            )}

            <div className={cn("flex flex-row justify-end", "space-x-2 p-3")}>
              <Button variant="translucent" onClick={onClose}>
                {t("cancel")}
              </Button>
              <Button
                variant="solid"
                theme="brand-blue"
                disabled={isSubmitting || !message}
                onClick={() => void submitForm()}
              >
                {t("send")}
              </Button>
            </div>
          </>
        )}
      </Formik>
    )
  },
)
FeedbackFormFn.displayName = "FeedbackForm"

export const FeedbackForm = React.memo(FeedbackFormFn) as typeof FeedbackFormFn
