import React, { useCallback } from "react"
import { cn } from "@daybridge/cn"
import { Button, Label } from "@daybridge/components"
import { useTranslations } from "next-intl"
import { Calendar } from "../../../../../../data/calendars/Calendar"
import { Input } from "../../../../../../components/input/Input"
import { styleForHue } from "../../../../../../lib/styleForHue"
import { H3 } from "../../../../../../components/heading/H3"
import { useCalendarOperations } from "../../../../../../data/calendars/useCalendars"

type CalendarDeletionProps = Omit<
  React.HTMLAttributes<HTMLDivElement>,
  "children"
> & {
  calendar?: Calendar
  onClose?: () => void
}

const CalendarDeletionFn = React.forwardRef(
  (props: CalendarDeletionProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { className, calendar, onClose, ...rest } = props

    const t = useTranslations("calendars")

    const [typedValue, setTypedValue] = React.useState("")
    const {
      delete: { mutateAsync: deleteCalendar },
    } = useCalendarOperations()

    const onConfirm = useCallback(() => {
      if (!calendar) {
        return
      }

      void deleteCalendar({
        input: {
          id: calendar.id,
        },
      }).then(() => {
        onClose?.()
      })
    }, [calendar, deleteCalendar, onClose])

    if (!calendar) {
      return null
    }

    return (
      <>
        <Label
          className="m-4"
          theme="adaptive"
          style={{
            ...styleForHue(calendar.defaultArea?.hue),
          }}
        >
          {calendar.name}
        </Label>
        <div
          ref={ref}
          className={cn("flex flex-col", "w-[25rem]", className)}
          style={{
            ...styleForHue(calendar.defaultArea?.hue),
          }}
          {...rest}
        >
          <div className="px-4 pb-4 space-y-1">
            <H3>{t("confirm_deletion")}</H3>
            <p className="text-base text-low-contrast leading-tight">
              {t("confirm_deletion_description")}
            </p>
          </div>
          <p className="text-base text-medium-contrast leading-tight font-semibold px-4 pb-4 select-text">
            {t("type_calendar_name", { calendarName: calendar?.name })}
          </p>
          <Input
            className={cn("self-stretch w-auto max-w-none", "py-2", "mx-4")}
            placeholder={calendar?.name}
            data-1p-ignore={true}
            data-lp-ignore={true}
            autoComplete="off"
            value={typedValue}
            onChange={(e) => setTypedValue(e.target.value)}
          />
          <div className={cn("flex flex-row justify-end", "space-x-2 p-3")}>
            <Button
              variant="translucent"
              className="w-fit"
              onClick={() => onClose?.()}
            >
              {t("cancel")}
            </Button>
            <Button
              theme="error"
              variant="solid"
              className="w-fit inline"
              disabled={
                !typedValue ||
                typedValue.toLowerCase() !== calendar?.name.toLowerCase()
              }
              onClick={onConfirm}
            >
              {t("delete")}
            </Button>
          </div>
        </div>
      </>
    )
  },
)
CalendarDeletionFn.displayName = "CalendarDeletion"

export const CalendarDeletion = React.memo(
  CalendarDeletionFn,
) as typeof CalendarDeletionFn
