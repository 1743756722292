import { cn } from "@daybridge/cn"
import { Combobox, ComboboxProps } from "@daybridge/components"
import React, { useEffect } from "react"
import { useEndFlow } from "../../app/[locale]/(boundary)/_providers/FlowProvider"
import { useCommandKMenu } from "./command-k-menu"

type CommandKProps = Omit<ComboboxProps, "root">

const CommandKFn = React.forwardRef(
  (props: CommandKProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { className, ...rest } = props
    const root = useCommandKMenu()
    const endFlow = useEndFlow()

    // Add an event listener to the window that listens for Cmd + K or
    // Cmd + Shift + P to close the menu by calling endFlow.
    useEffect(() => {
      const listener = (e: KeyboardEvent) => {
        if (
          (e.metaKey && e.key === "k") ||
          (e.metaKey && e.shiftKey && e.key === "p")
        ) {
          endFlow()
        }
      }
      window.addEventListener("keydown", listener)
      return () => window.removeEventListener("keydown", listener)
    }, [endFlow])

    return (
      <Combobox
        className={cn("w-[500px] max-w-full")}
        maxHeight="500px"
        ref={ref}
        inlineInput
        root={root}
        {...rest}
      />
    )
  },
)
CommandKFn.displayName = "CommandK"

export const CommandK = React.memo(CommandKFn) as typeof CommandKFn
