import {
  QueryClient,
  QueryClientProvider as ReactQueryClientProvider,
} from "@tanstack/react-query"
import { useCallback, useMemo } from "react"
import { useToast } from "@daybridge/components"
import { retryServerErrors } from "../../../../lib/graphql/retryers"
import { GraphQLError } from "../../../../lib/graphql/errors"

export const QueryClientProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const toast = useToast()
  const onError = useCallback(
    (e: unknown) => {
      const error = e as GraphQLError
      const toastParams = {
        // TODO TRANSLATION
        title: "Something went wrong",
        message: error.message || error.toString() || "Please try again",
        icon: "Warning",
      }
      toast.error(toastParams)
    },
    [toast],
  )

  const queryClient = useMemo(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          retry: retryServerErrors,
          useErrorBoundary: true,
          staleTime: 30000,
        },
        mutations: {
          retry: retryServerErrors,
          useErrorBoundary: false,
          onError,
        },
      },
    })
  }, [onError])

  return (
    <ReactQueryClientProvider client={queryClient}>
      {children}
    </ReactQueryClientProvider>
  )
}
