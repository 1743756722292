import { UseQueryOptions } from "@tanstack/react-query"
import { useCallback, useMemo } from "react"
import {
  SearchParticipantsQuery,
  SearchParticipantsQueryVariables,
} from "../_gen/operations"
import { GraphQLError } from "../../lib/graphql/errors"
import { User } from "../users/User"
import { useSearchParticipantsQuery } from "../_gen/hooks"
import { userFromGraphQL } from "../users/userFromGraphQL"
import { useTimeZone } from "../../app/[locale]/(boundary)/_providers/TimeZoneProvider"

export const useSearchParticipants = (
  variables?: SearchParticipantsQueryVariables,
  options?: UseQueryOptions<
    SearchParticipantsQuery,
    string | GraphQLError,
    User[]
  >,
) => {
  const { effective } = useTimeZone()

  const select = useCallback(
    (data: SearchParticipantsQuery): User[] => {
      return (data.searchParticipants?.edges || [])
        .map((participant) => {
          if (participant.node.__typename !== "User") {
            return null
          }
          return userFromGraphQL(participant.node, effective)
        })
        .filter((participant): participant is User => participant !== null)
    },
    [effective],
  )
  const optionsWithSelect = useMemo(() => {
    return {
      keepPreviousData: true,
      ...options,
      select,
    }
  }, [options, select])

  return useSearchParticipantsQuery(variables, optionsWithSelect)
}
