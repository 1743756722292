import { useMemo } from "react"
import { useTimeZone } from "next-intl"
import { useClientData } from "../client-data/useClientData"
import { useGeoData } from "../geodata/useGeoData"
import { TimeZoneWithCustomizations } from "./types"

interface UseUserTimeZonesOptions {
  // If `effectiveOnly` is true, only the effective time zone will be returned. This
  // is useful for hiding or collapsing additional time zones in the UI.
  effectiveOnly?: boolean
}

/**
 * `useUserTimeZones` returns an array of time zones that the user has selected to
 * display in the UI. This includes the effective time zone and any additional time
 * zones that the user has added. Customizations for each time zone are also included.
 */
export const useUserTimeZones = (
  opts?: UseUserTimeZonesOptions,
): TimeZoneWithCustomizations[] => {
  const { data } = useGeoData()
  const effectiveZoneId = useTimeZone()
  if (!effectiveZoneId) {
    throw new Error("Cannot determine current time zone")
  }
  const timeZones = useMemo(() => data?.timeZones ?? {}, [data])

  const [additionalTimeZoneIds] = useClientData("additionalTimeZones")
  const [customizations] = useClientData("timeZoneCustomizations")

  const zoneIds = useMemo(() => {
    if (opts?.effectiveOnly) {
      return [effectiveZoneId]
    }
    return additionalTimeZoneIds.find((zone) => zone === effectiveZoneId)
      ? additionalTimeZoneIds
      : [effectiveZoneId, ...additionalTimeZoneIds]
  }, [additionalTimeZoneIds, effectiveZoneId, opts?.effectiveOnly])

  return useMemo(() => {
    return zoneIds
      .map((zoneId) => {
        const zone = timeZones[zoneId]
        if (!zone) {
          return undefined
        }
        const customization = customizations?.[zoneId]

        return {
          ...zone,
          ...customization,
        }
      })
      .filter(Boolean) as TimeZoneWithCustomizations[]
  }, [customizations, timeZones, zoneIds])
}
