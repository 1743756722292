import { useCallback } from "react"
import { useToast } from "@daybridge/components"
import { useTranslations } from "next-intl"
import { useUploadFileMutation } from "../_gen/hooks"
import { UploadType } from "../_gen/types"

export const useUploadFile = () => {
  // Mutation to get an upload URL
  const { mutateAsync: getUploadUrl } = useUploadFileMutation()

  // Mutation to set the avatar after upload has completed
  const t = useTranslations("file_uploads")
  const toast = useToast()

  const uploadFile = useCallback(
    async (
      idempotencyKey: string,
      blob: Blob,
      uploadType: UploadType,
      mediaType: string,
    ) => {
      const { uploadFile: upload } = await getUploadUrl({
        input: {
          uploadType,
          idempotencyKey,
          mediaType,
          size: blob.size,
        },
      })

      if (!upload) {
        // Should never happen
        throw new Error("Upload URL was not returned")
      }

      // Upload data to the storage bucket
      await fetch(upload.uploadUrl, {
        method: "PUT",
        body: blob,
        headers: {
          "Content-Length": blob.size.toString(),
        },
      })

      // Set the avatar on the user
      return upload
    },
    [getUploadUrl],
  )

  return useCallback(
    (
      idempotencyKey: string,
      blob: Blob,
      uploadType: UploadType,
      mediaType: string,
    ) => {
      const filePromise = uploadFile(
        idempotencyKey,
        blob,
        uploadType,
        mediaType,
      )
      toast.fromPromise(
        filePromise,
        {
          title: t("uploading_file"),
        },
        {
          icon: "Polaroid",
          title: t("file_uploaded"),
        },
        {
          title: t("your_file_couldnt_be_uploaded"),
          message: t("please_try_again"),
        },
      )
      return filePromise
    },
    [uploadFile, toast, t],
  )
}
