"use client"

import { useSelectedLayoutSegments } from "next/navigation"
import React from "react"
import { Modal, ModalProps } from "@daybridge/components"
import { useRouter } from "../../../../lib/navigation"

const AppWideModalFn = (props: Omit<ModalProps, "content">) => {
  const { children, className, ...rest } = props
  const router = useRouter()

  const rootSegment = useSelectedLayoutSegments()
  const segment = useSelectedLayoutSegments("modal")

  const open = // No modal active
    segment[0] !== "__DEFAULT__" &&
    segment[1] !== "(.)" &&
    // Check we are not in the expanded view already
    segment[1] !== `(.)${rootSegment?.[0] || ""}`

  return (
    <Modal
      open={open}
      onOpenChange={(open) => {
        if (!open) router.push("/")
      }}
      content={children}
      {...rest}
    >
      {/* Render an invisible target that can be refocused when the modal closes.
      This is needed to fix a Chrome bug where infinite scrolling stops receiving scroll
      events, breaking the timeline. */}
      <div
        className="absolute -top-px -left-px w-px h-px opacity-0"
        tabIndex={-1}
      />
    </Modal>
  )
}
AppWideModalFn.displayName = "AppWideModal"

export const AppWideModal = React.memo(AppWideModalFn)
