import { useOptimisticMutations, useOptimisticQuery } from "@daybridge/optimism"
import { InfiniteData, UseInfiniteQueryOptions } from "@tanstack/react-query"
import { GraphQLError } from "graphql-request/dist/types"
import { v4 } from "uuid"
import useFetcher from "../../lib/graphql/fetcher"
import {
  LocationsDocument,
  CreateLocationDocument,
  DeleteLocationDocument,
  ReorderLocationsDocument,
  UpdateLocationDocument,
} from "../_gen/hooks"
import {
  LocationsQueryVariables,
  LocationsQuery,
  DeleteLocationMutationVariables,
  UpdateLocationMutationVariables,
  DeleteLocationMutation,
  UpdateLocationMutation,
  CreateLocationMutation,
  CreateLocationMutationVariables,
  ReorderLocationsMutation,
  ReorderLocationsMutationVariables,
} from "../_gen/operations"
import { placeFromGraphQL } from "./placeFromGraphQL"
import { Location } from "./types"

export const locationFromGraphQL = (
  location: NonNullable<LocationsQuery["locations"]>["edges"][0]["node"],
): Location => {
  return {
    _type: "Location",
    id: location.id,
    customName: location.customName || undefined,
    icon: location.icon?.id,
    place: location.place ? placeFromGraphQL(location.place) : undefined,
    suggested: location.suggested,
    pending: false,
  }
}

const marshalLocations = (data: InfiniteData<LocationsQuery>): Location[] =>
  data.pages.flatMap(
    (page) =>
      page.locations?.edges.map((edge) => {
        return locationFromGraphQL(edge.node)
      }) || [],
  ) || []

const createTemporaryLocation = (
  variables: CreateLocationMutationVariables,
): Location => ({
  _type: "Location",
  id: variables.input?.idempotencyKey || v4(),
  customName: variables.input.create?.customName || undefined,
  icon: variables.input.create?.icon?.id,
  place: undefined,
  suggested: false,
  pending: true,
})

const applyLocationPatch = (
  location: Location,
  patch: UpdateLocationMutationVariables,
) => {
  const {
    patch: { customName, icon },
  } = patch.input
  return {
    ...location,
    customName: customName || location.customName,
    icon: icon?.id || location.icon,
  }
}

export const useLocations = (
  variables?: LocationsQueryVariables,
  options?: UseInfiniteQueryOptions<LocationsQuery, GraphQLError, Location[]>,
) =>
  useOptimisticQuery({
    queryName: "Locations",
    variables,
    fetcher: useFetcher<LocationsQuery, LocationsQueryVariables>(
      LocationsDocument,
    ).bind(null, variables),
    fromNetworkObject: marshalLocations,
    options,
    getPageInfo: (data) => data.locations?.pageInfo,
    createTemporaryDomainObject: createTemporaryLocation,
    applyPatch: applyLocationPatch,
  })

export const useLocationOperations = () =>
  useOptimisticMutations({
    queryName: "Locations",
    additionalInvalidationKeys: [["SearchLocations"]],
    create: {
      fetcher: useFetcher<
        CreateLocationMutation,
        CreateLocationMutationVariables
      >(CreateLocationDocument),
    },
    update: {
      fetcher: useFetcher<
        UpdateLocationMutation,
        UpdateLocationMutationVariables
      >(UpdateLocationDocument),
    },
    delete: {
      fetcher: useFetcher<
        DeleteLocationMutation,
        DeleteLocationMutationVariables
      >(DeleteLocationDocument),
    },
    reorder: {
      fetcher: useFetcher<
        ReorderLocationsMutation,
        ReorderLocationsMutationVariables
      >(ReorderLocationsDocument),
    },
  })
