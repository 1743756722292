"use client"
import { Modal } from "@daybridge/components"
import React, { useCallback } from "react"
import { useEndFlow, useFlow } from "../_providers/FlowProvider"
import { CalendarDeletion } from "../(calendar)/_components/calendars/CalendarDeletion"
import { CommandK } from "../../../../menus/command-k/CommandK"
import { CalendarCreation } from "../(calendar)/_components/calendars/CalendarCreation"
import { CalendarEdit } from "../(calendar)/_components/calendars/CalendarEdit"
import { Feedback } from "../../../../menus/feedback/Feedback"
import { FeedbackForm } from "../feedback/FeedbackForm"

/**
 * Flow is a component that renders different content based on the current flow.
 * This approach helps us to centralise modal coordination logic and make sure
 * that only one modal is rendered at a time.
 */

const FlowFn: React.FC = () => {
  const flow = useFlow()
  const closeFlow = useEndFlow()
  const onOpenChange = useCallback(
    (open: boolean) => {
      if (!open) {
        closeFlow()
      }
    },
    [closeFlow],
  )

  return (
    <>
      <Modal
        open={flow?.id === "command_k"}
        onOpenChange={onOpenChange}
        content={<CommandK onComplete={() => onOpenChange(false)} />}
      />

      <Modal
        open={flow?.id === "feedback_triage"}
        onOpenChange={onOpenChange}
        content={<Feedback onComplete={() => onOpenChange(false)} />}
      />

      <Modal
        open={flow?.id === "feedback_form"}
        content={
          <FeedbackForm
            onClose={() => onOpenChange(false)}
            feedbackType={
              flow?.id === "feedback_form"
                ? flow.params.feedbackType
                : undefined
            }
          />
        }
      />

      <Modal
        open={flow?.id === "calendar_edit"}
        onOpenChange={onOpenChange}
        content={
          <CalendarEdit
            calendar={
              flow?.id === "calendar_edit" ? flow.params?.calendar : undefined
            }
            onClose={() => onOpenChange(false)}
          />
        }
      />
      <Modal
        open={flow?.id === "calendar_deletion"}
        onOpenChange={onOpenChange}
        content={
          <CalendarDeletion
            calendar={
              flow?.id === "calendar_deletion"
                ? flow.params.calendar
                : undefined
            }
            onClose={() => onOpenChange(false)}
          />
        }
      />
      <Modal
        open={flow?.id === "calendar_creation"}
        onOpenChange={onOpenChange}
        content={<CalendarCreation onClose={() => onOpenChange(false)} />}
        className="top-[5vh]"
      />
    </>
  )
}
FlowFn.displayName = "Flow"

export const Flow = React.memo(FlowFn) as typeof FlowFn
