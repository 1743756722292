import { array, object, number, string } from "yup"
import React from "react"
import { cn } from "@daybridge/cn"
import { useTranslations } from "next-intl"
import { Icon, Button, Dropdown } from "@daybridge/components"
import { FormikProps } from "formik"
import { Input } from "../../../../../../components/input/Input"
import {
  CreateCalendarCreateInput,
  IconId,
  UpdateCalendarPatchInput,
} from "../../../../../../data/_gen/types"
import { FormSection } from "../items/form/FormSection"

type AlertsSettingsProps = Omit<
  React.HTMLAttributes<HTMLDivElement>,
  "children"
> & {
  formik:
    | FormikProps<CreateCalendarCreateInput>
    | FormikProps<UpdateCalendarPatchInput>
}

const AlertsSettingsFn = React.forwardRef(
  (props: AlertsSettingsProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { formik, className, ...rest } = props
    const t = useTranslations("alerts")

    return (
      <div ref={ref} className={cn(className)} {...rest}>
        <div>
          {(formik.values.defaultEventAlerts || []).length +
            (formik.values.defaultAllDayEventAlerts || []).length >
          0 ? (
            <>
              {formik.values.defaultEventAlerts?.map((alert, index) => {
                return (
                  <FormSection key={index}>
                    <div className="text-base flex flex-row items-center pl-4 pr-4 py-2">
                      <Icon
                        className={cn("w-[0.9em] mr-[0.5em] text-low-contrast")}
                        name={IconId.Clock}
                      />

                      <div className="flex-1">
                        {t.rich("minutes_before_event", {
                          Minutes: () => (
                            <Input
                              className="w-20 mx-2"
                              min={0}
                              max={28 * 24 * 60} // 28 days
                              type="number"
                              {...formik.getFieldProps(
                                `defaultEventAlerts[${index}].minutes`,
                              )}
                              {...formik.getFieldMeta(
                                `defaultEventAlerts[${index}].minutes`,
                              )}
                            />
                          ),
                        })}
                      </div>
                      <Button
                        icon={IconId.TrashEmpty}
                        variant="translucent"
                        className="w-7 h-7"
                        onClick={() => {
                          void formik.setFieldValue(
                            "defaultEventAlerts",
                            formik.values.defaultEventAlerts?.filter(
                              (_, i) => i !== index,
                            ),
                          )
                        }}
                      />
                    </div>
                  </FormSection>
                )
              })}
              {formik.values.defaultAllDayEventAlerts?.map((alert, index) => {
                return (
                  <FormSection key={index}>
                    <div className="text-base flex flex-row items-center pl-4 pr-4 py-2">
                      <Icon
                        className={cn("w-[0.9em] mr-[0.5em] text-low-contrast")}
                        name={IconId.Calendar}
                      />

                      <div className="flex-1">
                        {t.rich("days_before_event", {
                          Days: () => (
                            <Input
                              className="w-16 mx-2"
                              min={0}
                              max={28}
                              type="number"
                              {...formik.getFieldProps(
                                `defaultAllDayEventAlerts[${index}].days`,
                              )}
                              {...formik.getFieldMeta(
                                `defaultAllDayEventAlerts[${index}].days`,
                              )}
                            />
                          ),
                          Time: () => (
                            <Input
                              className="w-24 mx-2"
                              type="time"
                              {...formik.getFieldProps(
                                `defaultAllDayEventAlerts[${index}].at`,
                              )}
                              {...formik.getFieldMeta(
                                `defaultAllDayEventAlerts[${index}].at`,
                              )}
                            />
                          ),
                        })}
                      </div>
                      <Button
                        icon={IconId.TrashEmpty}
                        variant="translucent"
                        className="w-7 h-7"
                        onClick={() => {
                          void formik.setFieldValue(
                            "defaultAllDayEventAlerts",
                            formik.values.defaultAllDayEventAlerts?.filter(
                              (_, i) => i !== index,
                            ),
                          )
                        }}
                      />
                    </div>
                  </FormSection>
                )
              })}
            </>
          ) : (
            <div
              className={cn(
                "flex flex-col items-center justify-center",
                "pt-6 pb-8 px-8",
              )}
            >
              <div className={cn("text-base text-high-contrast")}>
                {t("no_alerts")}
              </div>
              <div className={cn("text-sm text-medium-contrast")}>
                {t("no_alerts_description")}
              </div>
            </div>
          )}
        </div>
        <div
          className={cn(
            "flex flex-row items-center",
            "pt-3 pl-10 pr-4 space-x-2",
            "border-t border-tint-light",
          )}
        >
          {((formik.values.defaultEventAlerts || []).length < 5 ||
            (formik.values.defaultAllDayEventAlerts || []).length < 5) && (
            <Dropdown
              root={{
                items: [
                  ...((formik.values.defaultEventAlerts || []).length < 5
                    ? [
                        {
                          id: "timed-alert",
                          icon: IconId.Clock,
                          title: t("timed_alert"),
                          description: t("timed_alert_description"),
                          onSelect: () => {
                            // Take highest current value if any, add 5 minutes
                            const highestValue = Math.min(
                              Math.max(
                                ...(formik.values.defaultEventAlerts || []).map(
                                  (alert) => alert.minutes || 0,
                                ),
                                0,
                              ),
                              28 * 24 * 60 - 5,
                            )
                            void formik.setFieldValue("defaultEventAlerts", [
                              ...(formik.values.defaultEventAlerts || []),
                              { minutes: highestValue + 5 },
                            ])
                          },
                        },
                      ]
                    : []),
                  ...((formik.values.defaultAllDayEventAlerts || []).length < 5
                    ? [
                        {
                          id: "all-day-alert",
                          icon: IconId.Calendar,
                          title: t("all_day_alert"),
                          description: t("all_day_alert_description"),
                          onSelect: () => {
                            // Take the current highest value if any, add 1 day
                            const highestValue = Math.min(
                              Math.max(
                                ...(
                                  formik.values.defaultAllDayEventAlerts || []
                                ).map((alert) => alert.days || 0),
                                0,
                              ),
                              28 - 1,
                            )
                            void formik.setFieldValue(
                              "defaultAllDayEventAlerts",
                              [
                                ...(formik.values.defaultAllDayEventAlerts ||
                                  []),
                                { days: highestValue + 1, at: "08:00" },
                              ],
                            )
                          },
                        },
                      ]
                    : []),
                ],
              }}
              align="start"
            >
              <Button
                icon={IconId.Plus}
                chevron
                variant="solid"
                theme="adaptive"
                className="text-sm"
              >
                {t("add_alert")}
              </Button>
            </Dropdown>
          )}
          <div className="flex-1" />
          <Button
            className="text-sm"
            icon={IconId.Undo}
            onClick={() => {
              void formik.setFieldValue("defaultEventAlerts", null)
              void formik.setFieldValue("defaultAllDayEventAlerts", null)
            }}
          >
            {t("use_defaults")}
          </Button>
        </div>
      </div>
    )
  },
)
AlertsSettingsFn.displayName = "AlertsSettings"

export const AlertsSettings = React.memo(
  AlertsSettingsFn,
) as typeof AlertsSettingsFn

export const alertsValidationSchema = {
  defaultEventAlerts: array(
    object().shape({
      minutes: number()
        .integer()
        .min(0)
        .max(28 * 24 * 60)
        .required(),
    }),
  ).nullable(),
  defaultAllDayEventAlerts: array(
    object().shape({
      days: number().integer().min(0).max(28).required(),
      at: string()
        .required()
        .matches(/^[0-2][0-9]:[0-5][0-9]$/),
    }),
  ).nullable(),
}
